import React, { useEffect, useRef, useState } from "react"
import { 
   Box,
   Breadcrumb,
   BreadcrumbItem,
   BreadcrumbLink,
   Flex,
 } from "@chakra-ui/react"
import { Link as GatsbyLink } from 'gatsby'
import Header from './header.js';
import {Footer,Footerend} from './footer.js';
import Favicon1 from "../images/39442.png"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import {
   GoogleAd
} from '../util/functions.js';

// striptagsでHTMLタグを除去しsliceで最初の120文字だけを抜き取る関数
function sumarrize(html) {
   let striptags = require('striptags');
   var metaDescription = striptags(html).replace(/\r?\n/g, '').trim();
   metaDescription = metaDescription.replace(/\[.+?\]/g, '').trim();
   metaDescription = metaDescription.replace("&nbsp;", '').trim();
   return metaDescription.length <= 120
      ? metaDescription
      : metaDescription.slice(0, 120) + '...';
}
const MyBreadcrumb = ({uri,title}) => (
   <Box>
      <Breadcrumb pt="0.9rem" pb="1rem" pl="0.8rem" separator=">" textAlign={"left"}>
         <BreadcrumbItem>
            <BreadcrumbLink as={GatsbyLink} to="/">
               トップ
            </BreadcrumbLink>
         </BreadcrumbItem>
         <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink as={GatsbyLink} to={uri}>
            {
               {
                  "/disclaimers":"利用規約",
                  "/channellist":"チャンネルランキング",
                  "/channeldetail":"チャンネル詳細",
                  "/videolist":"ビデオランキング",
                  "/videodetail":"ビデオ詳細",
               }[uri] || title
            }
            </BreadcrumbLink>
         </BreadcrumbItem>
      </Breadcrumb>
   </Box>
)

const Layout = (props) => {

   // siteMetadata: {
   //    title: `TUBESEARCH`,
   //    description: `TUBESEARCHではYouTubeチャンネル,YouTubeビデオを様々な視点から紹介しています。`,
   //    url: `https://yt-search.net`,
   //    lang: "ja",
   //    twitterUsername: `@youtube2020s`,
   //    siteUrl: `https://yt-search.net`,
   // },
   const data = useStaticQuery(graphql`
     query {
       site {
         siteMetadata {
            defaultTitle: title
            defaultDescription: description
            siteUrl: url
            defaultLang: lang
            twitterUsername
         }
       }
     }
   `)

   const {
      defaultTitle,
      defaultDescription,
      siteUrl,
      defaultLang,
      twitterUsername,
   } = data.site.siteMetadata;
   
   const seo = {
      title: props.title ?  props.title + " | バズクラッチ" : defaultTitle,
      description: props.description || defaultDescription,
      image: props.image ? siteUrl + props.image : siteUrl + Favicon1, 
      lang: defaultLang,
      url: siteUrl + props.uri,
      canonicalUri: props.canonicalUri ? siteUrl + props.canonicalUri : null
   }

   return (
      <>
      <Helmet>
         <title>{seo.title}</title>
         {seo.canonicalUri && <link rel="canonical" href={seo.canonicalUri} />}
         <html lang={seo.lang} />
         <meta name="description" content={sumarrize(seo.description)} />
         <meta name="image" content={seo.image} />
         <meta property="og:url" content={seo.url} />
         <meta property="og:type" content="article" />
         <meta property="og:title" content={seo.title} />
         <meta property="og:description" content={sumarrize(seo.description)} />
         <meta property="og:image" content={seo.image} />
         <meta name="twitter:card" content="summary" />
         <meta name="twitter:creator" content={twitterUsername} />
         <meta name="twitter:title" content={seo.title} />
         <meta name="twitter:description" content={sumarrize(seo.description)} />
         <meta name="twitter:image" content={seo.image} />
         
         {/* https://gan0803.dev/blog/2021-01-06-lazy-load-adsense
         https://www.bugbugnow.net/2019/05/GoogleAdSense-lazy-loading.html */}
         {/* <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2462017370728663" crossOrigin="anonymous"></script> */}
         {/* <script type="application/javascript">
            {`
            (function(window, document) {
                  function main() {
                  // GoogleAdSense読込み
                  var ad = document.createElement('script');
                  ad.type = 'text/javascript';
                  ad.async = true;
                  ad.crossorigin = 'anonymous';

                  // 新コードの場合、サイト運営者IDを書き換えてコメントアウトを外す
                  // ad.dataset.adClient = 'ca-pub-xxxxxxxxxxxxxxxx';
                  ad.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2462017370728663';
                  var sc = document.getElementsByTagName('script')[0];
                  sc.parentNode.insertBefore(ad, sc);
                  }
            
                  // 遅延読込み
                  var lazyLoad = false;
                  function onLazyLoad() {
                  if (lazyLoad === false) {
                     // 複数呼び出し回避 + イベント解除
                     lazyLoad = true;
                     window.removeEventListener('scroll', onLazyLoad);
                     window.removeEventListener('mousemove', onLazyLoad);
                     window.removeEventListener('mousedown', onLazyLoad);
                     window.removeEventListener('touchstart', onLazyLoad);
                     window.removeEventListener('keydown', onLazyLoad);
                     main();
                  }
                  }
                  window.addEventListener('scroll', onLazyLoad);
                  window.addEventListener('mousemove', onLazyLoad);
                  window.addEventListener('mousedown', onLazyLoad);
                  window.addEventListener('touchstart', onLazyLoad);
                  window.addEventListener('keydown', onLazyLoad);
                  window.addEventListener('load', function() {
                  // ドキュメント途中（更新時 or ページ内リンク）
                  if (window.pageYOffset) {
                     onLazyLoad();
                  }
                  //何もアクションがないときは指定秒数後に読み込み開始（ミリ秒）
                  //window.setTimeout(onLazyLoad,3000)
                  });
            })(window, document);
            `}
         </script> */}
      </Helmet>
      <Header uri={props.uri} />

      <Box bg="#eeeeee">
         
            <Flex direction={{ base:"column",md:"row"}}>
               <Box  w={{ base:"auto",md:"calc(100vw - 300px - 2rem)"}}>
                  {props.uri && <MyBreadcrumb uri={seo.url} title={props.title} />}
                  <Box>
                     {props.children}
                  </Box>
                  <Footer path={seo.url} title={props.title ?  props.title : defaultTitle} twitterId={twitterUsername.replace("@", "")}/>
               </Box>
               <Box>
                  <Box m="0.5rem">
                     <GoogleAd />
                  </Box>
                  
                  <Box m="0.5rem" pos={{ base:"unset",md:"sticky"}} top={{base:"unset",md:"1rem"}}>
                     <GoogleAd format={"vertical"}/>
                  </Box>
               </Box>
            </Flex>
         
         <Footerend />

      </Box>
      </>
   )
}
export default Layout;