import React, { useState, useRef, useEffect } from "react"
import { 
   Box, 
   Link, 
   Text, 
   Heading,
   HStack,
   Image,
   Center,
   Divider,
   Drawer,
   DrawerBody,
   DrawerHeader,
   DrawerOverlay,
   DrawerContent,
   useDisclosure,
   Icon,
   Spinner,
} from "@chakra-ui/react"
import { Link as GatsbyLink } from 'gatsby'
import Favicon1 from "../images/39442.png"
import { 
   AiOutlineMenu,
   AiOutlineMail,
   AiOutlineRead,
   AiOutlineTwitter,
   AiOutlineClose } from "react-icons/ai"
import axios  from 'axios'
import { StaticImage } from "gatsby-plugin-image"

const MainDrawer = ({isOpen,onClose,btnRef}) => {

   const [data, setData] = useState("");
   useEffect(() => {
      
      var type="getChViCount";
      var userData = "";
      let postdata = Object.assign({type:type}, userData);

      axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
         .then( (res) => {
            setData(res.data);
         })
         .catch( (error) => {
            console.log(error);
            setData("");
      });
   }, []);
    
   return (
      <>
      <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      finalFocusRef={btnRef}
      >
         <DrawerOverlay />
         <DrawerContent>
            <DrawerHeader>
               <Center>
                  <Link as={GatsbyLink} to="/">
                     <Center>
                        <Box w={"2.5em"} h="auto">
                           <StaticImage
                              src="../images/39442.png" 
                              alt="バズクラッチ"
                              filter="saturate(500%)"
                              width={50}
                           />
                        </Box>
                     </Center>
                     <Heading my={2} as="h1" size="md" fontWeight="700" mb={"0"}>
                        バズクラッチ
                     </Heading>
                     <Box align="center" fontSize={"0.8rem"} fontWeight={"normal"} color="gray.500">
                        掲載チャンネル数 : 
                        {data === "" ? <> <Spinner size='xs' /></> : <> {data.channelCount}</>}
                     </Box>
                     <Box align="center" fontSize={"0.8rem"} fontWeight={"normal"} color="gray.500">
                        動画数 : 
                        {data === "" ? <> <Spinner size='xs' /></> : <> {data.videoCount}</>}
                     </Box>
                  </Link>
               </Center>
            </DrawerHeader>

            <DrawerBody bg="blackAlpha.900" color="rgba(255,255,255,.9)" px={0}>
               {/* <HStack p="1" ml={2}>
                  <Icon as={AiOutlineSearch} color="gray.500" fontSize="2rem"/>
                  <Box fontWeight="bold">検索</Box>
               </HStack> */}
               
               <Divider color="gray.600"/>
               <Link as={GatsbyLink} to="/category/howto/1">
                  <HStack p="1" ml={2}>
                     <Icon as={AiOutlineRead} color="gray.500" fontSize="2rem"/>
                     <Box fontWeight="bold">解説記事</Box>
                  </HStack>
               </Link>
               <Divider color="gray.600"/>
               <Link href={"https://twitter.com/youtube2020s"} textDecoration="none!important" isExternal>
                  <HStack p="1" ml={2}>
                     <Icon as={AiOutlineTwitter} color="gray.500" fontSize="2rem"/>
                     <Box fontWeight="bold">公式Twitter</Box>
                  </HStack>
               </Link>
               <Divider color="gray.600"/>
               <Link href={"/contact"} textDecoration="none!important">
                  <HStack p="1" ml={2}>
                     <Icon as={AiOutlineMail} color="gray.500" fontSize="2rem"/>
                     <Box fontWeight="bold">お問い合わせ</Box>
                  </HStack>
               </Link>
               <Divider color="gray.600"/>
               <HStack p="1" ml={2} onClick={onClose}>
                  <Icon as={AiOutlineClose} color="gray.500" fontSize="2rem"/>
                  <Box fontWeight="bold">閉じる</Box>
               </HStack>
               <Divider color="gray.600"/>
            </DrawerBody>

         </DrawerContent>

      </Drawer>
      </>
   )
}
const MenuDraw = () => {
   const { isOpen , onOpen, onClose } = useDisclosure()
   const btnRef = useRef()

   return (
      <>
      <Box ref={btnRef} onClick={onOpen}>
         <Center><Icon as={AiOutlineMenu} fontSize="1.4rem"/></Center>
         <Center><Text fontWeight="bold" fontSize="0.5rem" opacity="0.9">メニュー</Text></Center>
      </Box>

      <MainDrawer isOpen={isOpen} onClose={onClose} btnRef={btnRef}/>
      </>
   )
}

const Header = () => {
   
   return (
      <>
         <Box>
            <HStack>
               <Box m="1rem">
                  <Link as={GatsbyLink} to="/" textDecoration="none!important" _focus={{boxShadow:"none"}}>
                     <HStack>
                        <Box w={"2rem"}>
                           <StaticImage
                              src="../images/39442.png" 
                              alt="バズクラッチ"
                              filter="saturate(500%)"
                              width={32}
                           />
                        </Box>
                        <Heading fontSize="2rem" fontFamily="MyFont" opacity="0.9">
                           バズクラッチ
                        </Heading>
                     </HStack>
                  </Link>
               </Box>
               <Box position="absolute" right="0.8rem">
                  <MenuDraw />
               </Box>
            </HStack>
         </Box>
         {/* <Divider /> */}

         {/* <Box bg="#fafafa" h="1.5rem">
            <div class="ticker" rel="slide">
               <ul>
                  <li><a href="#2"> 【2000回突破おめでとう】なんちゃらチャンネル1000回突破　2020/07/01</a></li>
                  <li><a href="#3"> 【3000回突破おめでとう】なんちゃらチャンネル1000回突破　2020/07/01</a></li>
                  <li><a href="#4"> 【4000回突破おめでとう】なんちゃらチャンネル1000回突破　2020/07/01</a></li>
                  <li><a href="#5"> 【50000回突破おめでとう】なんちゃらチャンネル1000回突破　2020/07/01aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa</a></li> -->
               </ul>
            </div>
         </Box> */}
         
      </>
   )
}
export default Header;