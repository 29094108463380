import React, { useEffect, useRef, useState } from "react"
import { 
   Box,
   Heading,
   Center,
   Spinner,
   useRadio,
   useRadioGroup,
   Flex, 
   Link, 
   Text, 
   Icon,
   IconButton,
   Tooltip,
   Wrap,
   WrapItem,
   Collapse,
   Button,
   HStack,
   Input,
   AspectRatio,
   Image,
   Spacer,
   Badge,
   useControllableProp,
   useControllableState,
   UnorderedList,
   ListItem,
   Container,
   Accordion,
   AccordionItem,
   AccordionButton,
   AccordionPanel,
   AccordionIcon,

} from "@chakra-ui/react"
import { 
   AiFillYoutube,
   AiOutlineLeft,
   AiOutlineDoubleLeft,
   AiOutlineRight,
   AiOutlineDoubleRight
   
} from "react-icons/ai"
import { 
   RiMedalFill,
} from "react-icons/ri"
import Parser from "html-react-parser";
import { Link as GatsbyLink } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { 
   LineChart, 
   Line, 
   XAxis, 
   YAxis,
   ResponsiveContainer,
   Tooltip as RechartsTooltip,
   CartesianGrid,
   ComposedChart,
   Legend,
   Area,
   Bar,
   Brush,
   AreaChart,
   BarChart 
} from "recharts"
import axios  from 'axios'
import { Link as Scroll } from 'react-scroll';
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/css";
import "swiper/css/pagination"
import "swiper/css/navigation"
import SwiperCore, {
   Autoplay,Pagination,Navigation
 } from 'swiper';
SwiperCore.use([Autoplay,Pagination,Navigation]);


export const getWindowParam = (defaultParam) => {
   let urlParamStr = window.location.search;
   if (urlParamStr) {
      
      //?を除去
      urlParamStr = urlParamStr.substring(1)
      //urlパラメータをオブジェクトにまとめる
      urlParamStr.split('&').forEach( param => {
         const temp = param.split('=')
         //pramsオブジェクトにパラメータを追加
         if(temp[0] in defaultParam){
            defaultParam[temp[0]] = decodeURIComponent(temp[1])
         }
      })
   }
   return defaultParam;
}

// function for dynamic sorting
export const compareValues = (key, order='asc') => {
   return function(a, b) {
      if(!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
         // property doesn't exist on either object
            return 0; 
      }
   
      const varA = (typeof a[key] === 'string') ? 
         a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string') ? 
         b[key].toUpperCase() : b[key];
   
      let comparison = 0;
      if (varA > varB) {
         comparison = 1;
      } else if (varA < varB) {
         comparison = -1;
      }
      return (
         (order === 'desc') ? (comparison * -1) : comparison
      );
   };
}
// function for dynamic sorting
export const compareValues00 = (key, order='asc') => {
   return function(a, b) {
      if(!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
         // property doesn't exist on either object
            return 0; 
      }
   
      const varA = (typeof a[key] === 'string') ? 
      Number(a[key]): a[key];
      const varB = (typeof b[key] === 'string') ? 
      Number(b[key]) : b[key];
   
      let comparison = 0;
      if (varA > varB) {
         comparison = 1;
      } else if (varA < varB) {
         comparison = -1;
      }
      return (
         (order === 'desc') ? (comparison * -1) : comparison
      );
   };
}

export const num2ja = (num) => {
   const keta = ['', '千', '万', '億', '兆']

   let jaNum = ''
   let count = 0

   num = String(num).replace(',', '')

   // 千
   if(num.length <= 3) {
      return num
   }
   else {
      const n = num.slice(-3) - 0 + ''
      jaNum = [n !== '0' ? `${n}${keta[count]}` : '', jaNum].join('')
      num = (num / 1000 | 0) + ''
      count++
   }
   // 万
   if(num.length > 1) {
      const n = num.slice(-1) - 0 + ''
      jaNum = [n !== '0' ? `${n}${keta[count]}` : '', jaNum].join('')
      num = (num / 10 | 0) + ''
      count++
   }
   // それ以降
   while(num.length > 4) {
      const n = num.slice(-4) - 0 + ''
      jaNum = [n !== '0' ? `${n}${keta[count]}` : '', jaNum].join('')
      num = (num / 10000 | 0) + ''
      count++
   }
   jaNum = [num !== '0' ? `${num}${keta[count]}` : '', jaNum].join('')
   return jaNum
}
export const AutoBrLinkMoreParser = ({str,show,setShow}) => {
   const handleToggle = () => setShow(!show);

   var regexp_url = /(https?:\/\/\S+)/g;
   str = str.replace(regexp_url, (match) => {
      return '<a href="' + match + '">' + match + '</a>';
   })
   str = str.replaceAll(
      "\n",
      "<br />"
   );

   return (
      <>
      <Box>
         <Collapse startingHeight={"5rem"} in={show}>
            {Parser(str)}
         </Collapse>
         <Text size="sm" onClick={handleToggle} mt="1rem">
            {show ? "閉じる" : "続きを読む"}
         </Text>
      </Box>
      </>
   )
}

export const Midasi = (props) => {
   return (
      <Heading
         position="relative"
         px={5} py={2}
         mb={5}
         
         color="#333"
         bg="#fff"
         textShadow={"1px 1px 0 #fff, 2px 2px 0 #999"}
         borderY={"#333 solid 3px"}
         bgGradient="repeating-linear(-45deg, rgba(255, 255, 255, 0.0), rgba(0, 0, 0, 0.1) 1px)"
         _before={{
            content:`" "`,
            position:"absolute",
            top:"100%",
            left:"24px",
            width:"0",
            height:"0",
            borderWidth:"12px",
            borderStyle:"solid",
            borderColor:"transparent",
            borderTopColor:"#333",
         }}
         _after={{
            content:`" "`,
            position:"absolute",
            top:"100%",
            left:"28px",
            width:"0",
            height:"0",
            borderWidth:"8px",
            borderStyle:"solid",
            borderColor:"transparent",
            borderTopColor:"#f0f0f0",
            zIindex:"1",
         }}
      >
         {props.children}
      </Heading>
   )
}
export const Midasi2 = (props) => {
   // console.log(props)
   return (
      <Center px="1.6rem">
         <Heading
         as='h1'
         py="1.6rem"
         fontSize="2rem"
         letterSpacing="0.1rem"
         pb={props.pb}
         >
            {props.children}
         </Heading>
      </Center>
   )
}
export const Midasi3 = (props) => {
   return (
      <Heading
         pt="1.5rem"
         fontSize="1.6rem"
         mb={props.mb ? props.mb : "1rem"}
         textAlign="left"
         lineHeight="1.75rem"
      >
         {props.children}
      </Heading>
   )
}
export const Midasi4 = (props) => {
   return (
      <Center px="1rem">
         <Link href={"https://www.youtube.com/channel/" + props.ch}>
            <Heading
               pt="1.5rem"
               fontSize="1.6rem"
               mb={props.mb ? props.mb : "1rem"}
               textAlign="left"
               lineHeight="1.75rem"
               letterSpacing="tight"
            >
               {props.children}<Icon ml="2" as={AiFillYoutube} color="red" h={"1.7rem"} w={"1.7rem"}/>
            </Heading>
         </Link>
      </Center>
   )
}
export const Midasi4v1 = (props) => {
   return (
      <Center px="1rem">
         <Link href={"https://www.youtube.com/watch?v=" + props.ch}>
            <Heading
               pt="1.5rem"
               fontSize="1.6rem"
               mb={props.mb ? props.mb : "1rem"}
               textAlign="left"
               lineHeight="1.75rem"
               letterSpacing="tight"
            >
               {props.children}<Icon ml="2" as={AiFillYoutube} color="red" h={"1.7rem"} w={"1.7rem"}/>
            </Heading>
         </Link>
      </Center>
   )
}
export const Midasi5 = (props) => {
   return (
      <Heading
         pt="1.5rem"
         fontSize="1.6rem"
         mb={props.mb ? props.mb : "1rem"}
         ml="1.6rem"
         textAlign="left"
         lineHeight="1.75rem"
      >
         {props.children}
      </Heading>
   )
}

export const RadioButtons = ({items,name,defaultValue,handleclick}) => {

   function RadioCard(props) {
      const { getInputProps, getCheckboxProps } = useRadio(props)
      
      const input = getInputProps()
      const checkbox = getCheckboxProps()
      
      return (
         <Box as="label">
            <input {...input} />
            <Box
               {...checkbox}
               display="inline-block"
               px={4}
               py={2}
               m={1}
               cursor="pointer"
               borderWidth="1px"
               borderRadius="md"
               boxShadow="sm"
               fontWeight={"thin"}
               _checked={{
                  bg: "teal.600",
                  color: "white",
                  borderColor: "teal.600",
               }}
               _focus={{
                  boxShadow: "outline",
               }}
            >
               {props.children}
            </Box>
         </Box>
      )
   }
   
   const { getRootProps, getRadioProps } = useRadioGroup({
      name: name,
      defaultValue: defaultValue,
      onChange: handleclick,
   })

   const group = getRootProps()
   // const options = ["react", "vue", "svelte"]
   const options = items
   return (
      <>
      <Box {...group}>
         {React.Children.toArray(options.map((value) => {
            const radio = getRadioProps({ value })
            return (
               <RadioCard {...radio}>
                  {value}
               </RadioCard>
            )
         }))}
      </Box>
      </>
   )
}

export const RadioButtonsC1 = ({items,name,defaultValue,handleclick}) => {

   function RadioCard(props) {
      const { getInputProps, getCheckboxProps } = useRadio(props)
      
      const input = getInputProps()
      const checkbox = getCheckboxProps()
      
      return (
         <Box as="label">
            <input {...input} />
            <Box w="25%"
               {...checkbox}
               display="inline-block"
               px={4}
               py={2}
               cursor="pointer"
               borderWidth="1px"
               boxShadow="sm"
               fontWeight={"thin"}
               _checked={{
                  bg: "gray.600",
                  color: "white",
                  borderColor: "gray.600",
               }}
               _focus={{
                  boxShadow: "outline",
               }}
            >
               <Center>
               {props.children}
               </Center>
            </Box>
         </Box>
      )
   }
   
   const { getRootProps, getRadioProps } = useRadioGroup({
      name: name,
      defaultValue: defaultValue,
      onChange: handleclick,
   })

   const group = getRootProps()
   // const options = ["react", "vue", "svelte"]
   const options = items
   return (
      <>
      <Box {...group} m="1">
         {options.map((value) => {
            const radio = getRadioProps({ value })
            return (
               <RadioCard key={value} {...radio}>
                  {value}
               </RadioCard>
            )
         })}
      </Box>
      </>
   )
}

export const RadioButtonsC2 = ({items,name,defaultValue,handleclick}) => {

   function RadioCard(props) {
      const { getInputProps, getCheckboxProps } = useRadio(props)
      
      const input = getInputProps()
      const checkbox = getCheckboxProps()
      
      return (
         <Box as="label">
            <input {...input} />
            <Box w="50%"
               {...checkbox}
               display="inline-block"
               px={4}
               py={2}
               cursor="pointer"
               borderWidth="1px"
               boxShadow="sm"
               fontWeight={"thin"}
               _checked={{
                  bg: "gray.600",
                  color: "white",
                  borderColor: "gray.600",
               }}
               _focus={{
                  boxShadow: "outline",
               }}
            >
               <Center>
               {props.children}
               </Center>
            </Box>
         </Box>
      )
   }
   
   const { getRootProps, getRadioProps } = useRadioGroup({
      name: name,
      defaultValue: defaultValue,
      onChange: handleclick,
   })

   const group = getRootProps()
   // const options = ["react", "vue", "svelte"]
   const options = items
   return (
      <>
      <Box {...group} m="1">
         {options.map((value) => {
            const radio = getRadioProps({ value })
            return (
               <RadioCard key={value} {...radio}>
                  {value}
               </RadioCard>
            )
         })}
      </Box>
      </>
   )
}

export const RadioButtonsC3 = ({items,name,defaultValue,handleclick,isLoaded}) => {

   function RadioCardSpiner(props) {
      const { getInputProps, getCheckboxProps } = useRadio(props)
      
      const input = getInputProps()
      const checkbox = getCheckboxProps()
      
      return (
         <Box as="label">
            <input {...input} />
            <Box w="calc(100% / 3)"
               {...checkbox}
               display="inline-block"
               px={4}
               py={2}
               cursor={props.isLoaded?"pointer":"not-allowed"}
               borderWidth="1px"
               boxShadow="sm"
               fontWeight={"thin"}
               _checked={{
                  bg: "gray.600",
                  color: "white",
                  borderColor: "gray.600",
               }}
               _focus={{
                  boxShadow: "outline",
               }}
            >
               <Center>
                  <Spinner mx="2" display={props.isLoaded?"none":"block"} size="xs"/>
                  {props.children}
               </Center>
            </Box>
         </Box>
      )
   }
   const { getRootProps, getRadioProps } = useRadioGroup({
      name: name,
      defaultValue: defaultValue,
      onChange: handleclick,
   })

   const group = getRootProps()
   // const options = ["react", "vue", "svelte"]
   const options = items
   return (
      <>
      <Box {...group} m="1">
         
         {options.map((value, index) => {
            const radio = getRadioProps({ value })
            return (

               <RadioCardSpiner 
                  key={value} 
                  {...radio} 
                  isLoaded={index === 0?true:isLoaded} 
                  isDisabled={index === 0?false:!isLoaded}
               >
                  {value}
               </RadioCardSpiner>
            )
         })}
      </Box>
      </>
   )
}
export const RadioButtonsC4 = ({items,name,defaultValue,handleclick}) => {

   function RadioCard(props) {
      const { getInputProps, getCheckboxProps } = useRadio(props)
      
      const input = getInputProps()
      const checkbox = getCheckboxProps()
      
      return (
         <Box as="label">
            <input {...input} />
            <Box
               {...checkbox}
               display="inline-block"
               mr="0.6rem"
               mb="0.6rem"
               px="1.2rem"
               py="0.6rem"
               fontSize="0.8rem"
               lineHeight="0.8rem"
               borderRadius="1.4rem"
               bg="#fff"
               border="1px solid #cecece"
               fontWeight="bold"
               _checked={{
                  bg: "teal.600",
                  color: "white",
                  borderColor: "teal.600",
               }}
               _focus={{
                  boxShadow: "outline",
               }}
            >
               {props.children}
            </Box>
         </Box>
      )
   }
   
   const { getRootProps, getRadioProps } = useRadioGroup({
      name: name,
      defaultValue: defaultValue,
      onChange: handleclick,
   })

   const group = getRootProps()
   // const options = ["react", "vue", "svelte"]
   const options = items
   return (
      <>
      <Box {...group}>
         {React.Children.toArray(options.map((value) => {
            const radio = getRadioProps({ value })
            return (
               <RadioCard {...radio}>
                  {value}
               </RadioCard>
            )
         }))}
      </Box>
      </>
   )
}

export const RadioButtonsC5 = ({items,name,defaultValue,handleclick}) => {

   function RadioCard(props) {
      const { getInputProps, getCheckboxProps } = useRadio(props)
      
      const input = getInputProps()
      const checkbox = getCheckboxProps()
      
      return (
         <Box as="label">
            <input {...input} />
            <Box w="25%"
               {...checkbox}
               display="inline-block"
               cursor="pointer"
               borderRight="0.1rem solid #eeeeee"
               padding="0.5rem 0"
               color="#fff"
               bg="#d82800"
               fontWeight={"bold"}
               fontSize="0.8rem"
               _checked={{
                  bg: "#fff",
                  padding:"1rem 0 0.5rem",
                  color:"#000",
               }}
               _focus={{
                  boxShadow: "outline",
               }}
            >
               <Center>
               {props.children}
               </Center>
            </Box>
         </Box>
      )
   }
   
   const { getRootProps, getRadioProps } = useRadioGroup({
      name: name,
      defaultValue: defaultValue,
      onChange: handleclick,
   })

   const group = getRootProps()
   // const options = ["react", "vue", "svelte"]
   const options = items
   return (
      <>
      <Box {...group}>
         {options.map((value) => {
            const radio = getRadioProps({ value })
            return (
               <RadioCard key={value} {...radio}>
                  {value}
               </RadioCard>
            )
         })}
      </Box>
      </>
   )
}

export const RadioButtonsC5v1 = ({items,name,defaultValue,handleclick,isDisabled}) => {

   function RadioCard(props) {
      const { getInputProps, getCheckboxProps } = useRadio(props)
      
      const input = getInputProps()
      const checkbox = getCheckboxProps()
      
      return (
         <Box as="label">
            <input {...input} />
            <Box w="25%"
               {...checkbox}
               
               display="inline-block"
               cursor={props.isDisabled?"not-allowed":"pointer"}
               borderRight="0.1rem solid #eeeeee"
               padding="0.5rem 0"
               color="#fff"
               bg={props.isDisabled?"#ffcfc4":"#d82800"}
               fontWeight={"bold"}
               fontSize="0.8rem"
               _checked={{
                  bg: "#fff",
                  padding:"1rem 0 0.5rem",
                  color:"#000",
               }}
               _focus={{
                  boxShadow: "outline",
               }}
            >
               <Center>
               {props.children}
               </Center>
            </Box>
         </Box>
      )
   }
   
   const { getRootProps, getRadioProps } = useRadioGroup({
      name: name,
      defaultValue: defaultValue,
      onChange: handleclick,
   })

   const group = getRootProps()
   // const options = ["react", "vue", "svelte"]
   const options = items

   return (
      <>
      <Box {...group}>
         {options.map((value,index) => {
            const radio = getRadioProps({ value })
            return (
               <RadioCard 
                  key={value} 
                  {...radio} 
                  isDisabled={isDisabled && index !== 0? true:false}
               >
                  {value}
               </RadioCard>
            )
         })}
      </Box>
      </>
   )
}

export const RadioButtonsC6 = ({items,name,defaultValue,handleclick}) => {

   function RadioCard(props) {
      const { getInputProps, getCheckboxProps } = useRadio(props)
      
      const input = getInputProps()
      const checkbox = getCheckboxProps()
      
      return (
         <Box as="label">
            <input {...input} />
            <Box w="50%"
               {...checkbox}
               display="inline-block"
               padding="0.5rem 0"
               cursor="pointer"
               borderWidth="1px"
               boxShadow="sm"
               fontWeight={"bold"}
               fontSize="0.8rem"
               // position="relative"
               _checked={{
                  
                  bg: "gray.600",
                  color: "white",
                  borderColor: "gray.600",
                  // _before:{
                  //    position:"absolute",
                  //    content:'"1"',
                  //    top:"0.5rem",
                  //    left:0
                  // }
               }}
               _focus={{
                  boxShadow: "outline",
               }}
            >
               <Center>
               {props.children}
               </Center>
            </Box>
         </Box>
      )
   }
   
   const { getRootProps, getRadioProps } = useRadioGroup({
      name: name,
      defaultValue: defaultValue,
      onChange: handleclick,
   })

   const group = getRootProps()
   // const options = ["react", "vue", "svelte"]
   const options = items
   return (
      <>
      <Box {...group} my={"0.5rem"}>
         {options.map((value) => {
            const radio = getRadioProps({ value })
            return (
               <RadioCard key={value} {...radio}>
                  {value}
               </RadioCard>
            )
         })}
      </Box>
      </>
   )
}

export const RadioButtonsC7 = ({items,name,defaultValue,handleclick}) => {

   function RadioCard(props) {
      const { getInputProps, getCheckboxProps } = useRadio(props)
      
      const input = getInputProps()
      const checkbox = getCheckboxProps()
      
      return (
         <Box as="label">
            <input {...input} />
            <Box w="calc(100%/3)"
               {...checkbox}
               display="inline-block"
               cursor="pointer"
               borderRight="0.1rem solid #eeeeee"
               padding="0.5rem 0"
               color="#fff"
               bg="#d82800"
               fontWeight={"bold"}
               fontSize="0.8rem"
               _checked={{
                  bg: "#fff",
                  padding:"1rem 0 0.5rem",
                  color:"#000",
               }}
               _focus={{
                  boxShadow: "outline",
               }}
            >
               <Center>
               {props.children}
               </Center>
            </Box>
         </Box>
      )
   }
   
   const { getRootProps, getRadioProps } = useRadioGroup({
      name: name,
      defaultValue: defaultValue,
      onChange: handleclick,
   })

   const group = getRootProps()
   // const options = ["react", "vue", "svelte"]
   const options = items
   return (
      <>
      <Box {...group}>
         {options.map((value) => {
            const radio = getRadioProps({ value })
            return (
               <RadioCard key={value} {...radio}>
                  {value}
               </RadioCard>
            )
         })}
      </Box>
      </>
   )
}


export const Pagenavi1 = ({params,pagecounts,pager,setPager,handleclick}) => {

   useEffect(() =>{
      
      if(pager.page+1 >= pagecounts){
         setPager({
            ...pager,
            isEndpage:true
         })
      }else{
         setPager({
            ...pager,
            isEndpage:false
         })
      }

   },[pagecounts]);

   return (
      <Center p="3rem">
         <Flex>
            <Tooltip label="First Page">
            <IconButton
               mr={"0.8rem"}
               colorScheme="teal"
               onClick={(e)=>{
                  // console.log(e);
                  // console.log("handleclick");
                  let temppage=1;
                  setPager({
                     page:temppage,
                     isFirstpage:true,
                     isEndpage:false
                  })
                  let tempP = {
                     ...params,
                     page:temppage,
                  };
                  window.scrollTo(0, 0);
                  handleclick(tempP);
               }}
               isDisabled={pager.isFirstpage}
               // isDisabled={true}
               // isDisabled={false}
               icon={<Icon as={AiOutlineDoubleLeft} h={4} w={4} />}
            />
            </Tooltip>
            <Tooltip label="Previous1 Page">
            <IconButton
               mr={"0.8rem"}
               colorScheme="teal"
               onClick={()=>{
                  let temppage=pager.page-1;
                  setPager({
                     page:temppage,
                     isFirstpage:temppage <= 1 ? true:false,
                     isEndpage:false
                  })
                  let tempP = {
                     ...params,
                     page:temppage,
                  };
                  window.scrollTo(0, 0);
                  handleclick(tempP);
               }}
               isDisabled={pager.isFirstpage}
               icon={<Icon as={AiOutlineLeft} h={4} w={4} />}
            />
            </Tooltip>

            <Center fontSize="0.8rem" fontWeight="bold">
               <Wrap spacing="0" justify="center">
                  <WrapItem>
                     {pager.page}
                  </WrapItem>
                  <WrapItem>
                     {"/" + pagecounts}
                  </WrapItem>
               </Wrap>
            </Center>

            <Tooltip label="Next Page">
            <IconButton
               ml={"0.8rem"}
               colorScheme="teal"
               onClick={() => {
                  let temppage=pager.page+1;
                  
                  setPager({
                     page:temppage,
                     isFirstpage:false,
                     isEndpage:temppage >= pagecounts ? true:false
                  })
                  let tempP = {
                     ...params,
                     page:temppage,
                  };
                  window.scrollTo(0, 0);
                  handleclick(tempP);
               }}
               isDisabled={pager.isEndpage}
               icon={<Icon as={AiOutlineRight} h={4} w={4} />}
            />
            </Tooltip>
         
            <Tooltip label="Last Page">
            <IconButton
               ml={"0.8rem"}
               colorScheme="teal"
               onClick={(e) => {
                  let temppage=pagecounts;
                  setPager({
                     page:temppage,
                     isFirstpage:false,
                     isEndpage:true
                  })
                  let tempP = {
                     ...params,
                     page:temppage,
                  };
                  window.scrollTo(0, 0);
                  handleclick(tempP);
               }}
               isDisabled={pager.isEndpage}
               // isDisabled={params.isEndpage}
               icon={<Icon as={AiOutlineDoubleRight} h={4} w={4} />}
            />
            </Tooltip>

         </Flex>
      </Center>
   )
}

export const Pagenavi2 = ({params,pagecounts,pager,setPager,handleclick}) => {
   useEffect(() =>{
      if(pager.page+1 >= pagecounts){
         setPager({
            ...pager,
            isEndpage:true
         })
      }else{
         setPager({
            ...pager,
            isEndpage:false
         })
      }
   },[pagecounts]);

   let pagenombers = [];
   if(pager.page <= 2){
      pagenombers = [1,2,3,4];
   }else{
      pagenombers = [pager.page-1,pager.page,pager.page+1,pager.page+2];
   }

   return (
      <Center p="3rem">
         <Flex>
            
            <IconButton
               mr={"0.8rem"}
               colorScheme="teal"
               onClick={(e)=>{
                  // console.log(e);
                  // console.log("handleclick");
                  let temppage=1;
                  setPager({
                     page:temppage,
                     isFirstpage:true,
                     isEndpage:false
                  })
                  let tempP = {
                     ...params,
                     page:temppage,
                  };
                  window.scrollTo(0, 0);
                  handleclick(tempP);
               }}
               isDisabled={pager.isFirstpage}
               // isDisabled={true}
               // isDisabled={false}
               icon={<Icon as={AiOutlineDoubleLeft} h={4} w={4} />}
            />

            {
               React.Children.toArray(pagenombers.map((val) => {
                  return (
                     <Button 
                        mr={"0.8rem"}
                        colorScheme="teal"
                        onClick={()=>{
                           let temppage=val;
                           setPager({
                              page:temppage,
                              isFirstpage:temppage <= 1 ? true:false,
                              isEndpage:false
                           })
                           let tempP = {
                              ...params,
                              page:temppage,
                           };
                           window.scrollTo(0, 0);
                           handleclick(tempP);
                        }}
                        isActive={val==pager.page}
                     >{val}</Button >
                     
                  )
               }))
            }

            {/* <IconButton
               colorScheme="teal"
               onClick={() => {
                  let temppage=pager.page+1;
                  
                  setPager({
                     page:temppage,
                     isFirstpage:false,
                     isEndpage:temppage >= pagecounts ? true:false
                  })
                  let tempP = {
                     ...params,
                     page:temppage,
                  };
                  window.scrollTo(0, 0);
                  handleclick(tempP);
               }}
               // isDisabled={pager.isEndpage}
               isDisabled={false}
               icon={<Icon as={AiOutlineRight} h={4} w={4} />}
            /> */}

         </Flex>
      </Center>
   )
}

export const Pagenavi3 = ({pageContext}) => {
   return (
      <Center mt={3}>
         <Flex>
            <Link as={GatsbyLink} to={pageContext.firstPagePath}>
               <Tooltip label="First Page">
                  <IconButton
                  // onClick={() => gotoPage(0)}
                  isDisabled={!pageContext.previousPagePath}
                  icon={<AiOutlineDoubleLeft h={3} w={3} />}
                  mr={4}
                  />
               </Tooltip>
            </Link>
            <Link as={GatsbyLink} to={pageContext.previousPagePath}>
               <Tooltip label="Previous1 Page">
                  <IconButton
                  // onClick={previousPage}
                  isDisabled={!pageContext.previousPagePath}
                  icon={<AiOutlineLeft h={6} w={6} />}
                  />
               </Tooltip>
            </Link>
            <Box m={2}>
               <Text flexShrink="0" fontSize="1rem">
                  {" "}
                  <Text fontWeight="bold" as="span">
                  {pageContext.pageNumber}
                  </Text>
                  {" "}
                  /
                  {" "}
                  <Text fontWeight="bold" as="span">
                  {pageContext.pageCount}
                  </Text>
               </Text>
            </Box>

            <Link as={GatsbyLink} to={pageContext.nextPagePath}>
               <Tooltip label="Next Page">
                  <IconButton
                  // onClick={previousPage}
                  isDisabled={!pageContext.nextPagePath}
                  icon={<AiOutlineRight h={6} w={6} />}
                  />
               </Tooltip>
            </Link>
            
            <Link as={GatsbyLink} to={pageContext.lastPagePath}>
               <Tooltip label="Last Page">
                  <IconButton
                  // onClick={() => gotoPage(pageCount - 1)}
                  isDisabled={!pageContext.nextPagePath}
                  icon={<AiOutlineDoubleRight h={3} w={3} />}
                  ml={4}
                  />
               </Tooltip>
            </Link>
            
         </Flex>
      </Center>
   )
}

export const sumarrize = (html) => {
   let striptags = require('striptags');
   var metaDescription = striptags(html).replace(/\r?\n/g, '').trim();
   metaDescription = metaDescription.replace(/\[.+?\]/g, '').trim();
   metaDescription = metaDescription.replace("&nbsp;", '').trim();
   return metaDescription.length <= 120
      ? metaDescription
      : metaDescription.slice(0, 120) + '...';
}

export const CommaInput1 = ({settaget,step}) => {
   const [ch_viewTarget,setch_viewTarget] = useState({
      num:0,
      numcomma:"0",
   });

   return (
      <>
      <HStack>
         <Button 
            colorScheme="teal"
            onClick={()=>
               {
                  setch_viewTarget({
                     ...ch_viewTarget,
                     num:ch_viewTarget.num+step,
                     numcomma:(ch_viewTarget.num+step).toLocaleString()
                  });
                  settaget(ch_viewTarget.num+step);
               }
            }
         >+</Button>
         <Input 
            bg="whiteAlpha.900"
            textAlign="right"
            onChange={(e) => 
               {
                  // 全角？
                  let val = e.target.value;
                  let removed = val.replace(/,/g, '');
                  let isValid = /(\d+)/.test(removed);
                  if (isValid) {
                     let parseVal = parseInt(removed, 10);
                     setch_viewTarget({
                        ...ch_viewTarget,
                        num:parseVal,
                        numcomma:parseVal.toLocaleString()
                     });
                     settaget(parseVal);
                  }else{
                     setch_viewTarget({
                        ...ch_viewTarget,
                        num:0,
                        numcomma:"0"
                     });
                     settaget(0);
                  }
               }
            }
            value={ch_viewTarget.numcomma}
         />
         <Button 
            colorScheme="teal"
            onClick={()=>
               {
                  setch_viewTarget({
                     ...ch_viewTarget,
                     num:ch_viewTarget.num-step,
                     numcomma:(ch_viewTarget.num-step).toLocaleString()
                  });
                  settaget(ch_viewTarget.num-step);
               }
            }
         >-</Button>
      </HStack>
      </>
   )
}

//【React】フォーカスアウトするとカンマ区切りで表示される入力欄
//https://qiita.com/naoki-funawatari/items/b0f33a9193783e33b4b5
export const CommaInput2 = ({settaget,step}) => {
   const inputRef = useRef();
   const [value, setValue] = useState(0);
   const [displayValue,setdisplayValue] = useState("0");
   return (
      <>
      <HStack>
         <Button 
            colorScheme="teal"
            onClick={()=>
               {
                  setValue(value+step);
                  setdisplayValue((value+step).toLocaleString());

                  settaget(value+step);
               }
            }
         >+</Button>
         <Input 
            inputMode="numeric"
            bg="whiteAlpha.900"
            textAlign="right"
            onFocus={(e) => {
               setdisplayValue(value);
            }}
            
            onBlur={(e) => {
               let val = e.target.value;
               let removed = val.replace(/,/g, '');
               let isValid = /(\d+)/.test(removed);
               if (isValid) {
                  
                  let parseVal = parseInt(removed, 10);
                  setValue(parseVal);
                  setdisplayValue(parseVal.toLocaleString());
                  settaget(parseVal);
               }else{
                  setValue(0);
                  setdisplayValue("0");
                  settaget(0);
               }
            }}
            // defaultValue="0"
            onChange={(e) => setdisplayValue(e.target.value)}
            value={displayValue}
         />
         <Button 
            colorScheme="teal"
            onClick={()=>
               {
                  
                  setValue(value-step);
                  setdisplayValue((value-step).toLocaleString());

                  settaget(value-step);
               }
            }
         >-</Button>
      </HStack>
      </>
   )
}


export const TitleList1 = ({postList,to}) => {
   return (
      <Box px="0.75rem" display="flex" flexWrap="wrap">
         {React.Children.toArray(postList.map((node,index) => {
            
            return (
            <Box bg="#fff" mb="1rem" w="50%" verticalAlign="top" boxSizing="border-box" 
               borderRight={(index+1)%2 == 0 ?"none":"0.375rem solid #eee"}
               borderLeft={(index+1)%2 == 0 ?"0.375rem solid #eee":"none"}
            >
               <Link as={GatsbyLink} to={node.uri}>
                  <Box pos="relative">
                     <AspectRatio w="100%" ratio={16 / 9}>
                        {
                           node.featuredImage?
                              <GatsbyImage 
                                 image={node.featuredImage.node.localFile.childImageSharp.gatsbyImageData} 
                                 alt={node.featuredImage.node.altText} 
                              />
                              
                           :
                              <Image 
                                 w="100%" 
                                 verticalAlign="bottom" 
                                 fallbackSrc="https://via.placeholder.com/337x190" 
                              />
                        }
                     </AspectRatio>
                     {node.tags.nodes && 
                        <Box pos="absolute" top="0.5rem" left="0.5rem" >
                           {React.Children.toArray(node.tags.nodes.map((tag,index) => {
                              return (
                                 <Badge colorScheme="green" mr="0.2rem">
                                    {tag.name}
                                 </Badge>
                              )
                           }))}
                        </Box>
                     }
                  </Box>
                  
                  <Text
                     pt="1rem"
                     pr="0.5rem"
                     textAlign="right"
                     bg="#fff"
                     fontSize="0.7rem"
                     
                  >
                     {node.modifiedGmt}
                  </Text>
                  <Text
                     pt="0.5rem"
                     pb="1rem"
                     px="1rem"
                     bg="#fff"
                     fontSize="1.0rem"
                     fontWeight="bold"
                     lineHeight="1.5rem"
                     boxSizing="border-box"
                     wordBreak="break-all"
                  >
                     {node.title}
                  </Text>
                  
                  
               </Link>
            </Box>
            )
         }))
         }

         <Text w="100%"
            rounded="sm"
            fontSize="1.3rem"
            fontWeight="bold"
            lineHeight="4rem"
            textAlign="center"
            color="#fff"
            bg="linkedin.800"
            position="relative"
            _before={{
               content:'""',
               display:"block",
               position:"absolute",
               top:"1.6rem",
               right:"2rem",
               w:"1rem",
               h:"1rem",
               borderTop:"0.2rem solid #fff",
               borderRight:"0.2rem solid #fff",
               transform:"rotate(45deg)"
            }}
            as={GatsbyLink}
            to={to}
         >もっと見る</Text>
      </Box> 
   )
}

export const TitleList2 = ({postList,to}) => {
   return (
      <Box px="0.75rem" display="flex" flexWrap="wrap">
         {React.Children.toArray(postList.map((node,index) => {
            
            return (
               <>
               <Link as={GatsbyLink} to={node.uri} w="100%">
                  <HStack bg="#fff" mb="1rem"  verticalAlign="top" boxSizing="border-box">
                     <Box w="70%"> 
                        <Text
                           pt="1.25rem"
                           pb="0.5rem"
                           px="1rem"
                           bg="#fff"
                           fontSize="1.0rem"
                           fontWeight="bold"
                           lineHeight="1.5rem"
                           boxSizing="border-box"
                           wordBreak="break-all"
                        >
                           {node.title}
                        </Text>
                        <Wrap
                           px="1rem"
                           pb="0.5rem"
                        >
                           <WrapItem>
                              {node.tags.nodes && 
                                 React.Children.toArray(node.tags.nodes.map((tag,index) => {
                                    return (
                                       <Badge
                                          mr="0.2rem" 
                                          colorScheme="green"
                                       >
                                          {tag.name}
                                       </Badge>
                                    )
                                 }))
                              }
                           </WrapItem>
                           <Spacer />
                           <WrapItem 
                              bg="#fff"
                              fontSize="0.7rem"
                              textAlign="right"
                           >
                              {node.modifiedGmt}
                           </WrapItem>
                        </Wrap>
                     </Box>
                     <Box w="30%"> 
                        <AspectRatio w="culc(100%-0.5rem)" ratio={10 / 9}  m="0.5rem">
                           {
                              node.featuredImage?
                                 <GatsbyImage 
                                    image={node.featuredImage.node.localFile.childImageSharp.gatsbyImageData} 
                                    alt={node.featuredImage.node.altText} 
                                 />
                                 
                              :
                                 <Image 
                                    w="100%" 
                                    verticalAlign="bottom" 
                                    fallbackSrc="https://via.placeholder.com/337x190" 
                                 />
                           }
                        </AspectRatio>
                     </Box>
                  </HStack>
               </Link>
               </>
            )
         }))
         }

         <Text w="100%"
            rounded="sm"
            fontSize="1.3rem"
            fontWeight="bold"
            lineHeight="4rem"
            textAlign="center"
            color="#fff"
            bg="linkedin.800"
            position="relative"
            _before={{
               content:'""',
               display:"block",
               position:"absolute",
               top:"1.6rem",
               right:"2rem",
               w:"1rem",
               h:"1rem",
               borderTop:"0.2rem solid #fff",
               borderRight:"0.2rem solid #fff",
               transform:"rotate(45deg)"
            }}
            as={GatsbyLink}
            to={to}
         >もっと見る</Text>
      </Box> 
   )
}

export const TitleList2_1 = ({postList,to}) => {
   return (
      <Box px="0.75rem" display="flex" flexWrap="wrap">
         {React.Children.toArray(postList.map((node,index) => {
            
            return (
               <>
               <Link as={GatsbyLink} to={node.uri} w="100%">
                  <HStack bg="#fff" mb="1rem" w="100%" verticalAlign="top" boxSizing="border-box">
                     <Box w="70%"> 
                        <Text
                           pt="1.25rem"
                           pb="0.5rem"
                           px="1rem"
                           bg="#fff"
                           fontSize="1.0rem"
                           fontWeight="bold"
                           lineHeight="1.5rem"
                           boxSizing="border-box"
                           wordBreak="break-all"
                        >
                           {node.title}
                        </Text>
                        <Wrap
                           px="1rem"
                           pb="0.5rem"
                        >
                           <WrapItem>
                              {node.tags.nodes && 
                                 React.Children.toArray(node.tags.nodes.map((tag,index) => {
                                    return (
                                       <Badge
                                          mr="0.2rem" 
                                          colorScheme="green"
                                       >
                                          {tag.name}
                                       </Badge>
                                    )
                                 }))
                              }
                           </WrapItem>
                           <Spacer />
                           <WrapItem 
                              bg="#fff"
                              fontSize="0.7rem"
                              textAlign="right"
                           >
                              {node.modifiedGmt}
                           </WrapItem>
                        </Wrap>
                     </Box>
                     <Box w="30%"> 
                        <AspectRatio w="culc(100%-0.5rem)" ratio={10 / 9}  m="0.5rem">
                           {
                              node.featuredImage?
                                 <GatsbyImage 
                                    image={node.featuredImage.node.localFile.childImageSharp.gatsbyImageData} 
                                    alt={node.featuredImage.node.altText} 
                                 />
                              :
                                 <Image 
                                    w="100%" 
                                    verticalAlign="bottom" 
                                    fallbackSrc="https://via.placeholder.com/337x190" 
                                 />
                           }
                        </AspectRatio>
                     </Box>
                  </HStack>
               </Link>
               </>
            )
         }))
         }
      </Box> 
   )
}

export const TitleList3 = ({postList,to}) => {
   return (
      <Box px="0.75rem" display="flex" flexWrap="wrap">
         {React.Children.toArray(postList.map((node,index) => {
            
            return (
               <>
               <Link as={GatsbyLink} to={node.uri} w="100%">
                  <HStack bg="#fff" mb="1rem" w="100%" verticalAlign="top" boxSizing="border-box">
                     <Box w="30%"> 
                        <AspectRatio w="culc(100%-0.5rem)" ratio={10 / 9}  m="0.5rem">
                           {
                              node.featuredImage?
                                 <GatsbyImage 
                                    image={node.featuredImage.node.localFile.childImageSharp.gatsbyImageData} 
                                    alt={node.featuredImage.node.altText} 
                                 />
                              :
                                 <Image 
                                    w="100%" 
                                    verticalAlign="bottom" 
                                    fallbackSrc="https://via.placeholder.com/337x190" 
                                 />
                           }
                        </AspectRatio>
                     </Box>
                     <Box w="70%"> 
                        <Text
                           py="1.25rem"
                           px="1rem"
                           bg="#fff"
                           fontSize="1.0rem"
                           fontWeight="bold"
                           lineHeight="1.5rem"
                           boxSizing="border-box"
                           wordBreak="break-all"
                        >
                           {node.title}
                        </Text>
                        <Wrap
                           px="1rem"
                           pb="0.5rem"
                        >
                           <WrapItem>
                              {node.tags.nodes && 
                                 React.Children.toArray(node.tags.nodes.map((tag,index) => {
                                    return (
                                       <Badge
                                          mr="0.2rem" 
                                          colorScheme="green"
                                       >
                                          {tag.name}
                                       </Badge>
                                    )
                                 }))
                              }
                           </WrapItem>
                           <Spacer />
                           <WrapItem 
                              bg="#fff"
                              fontSize="0.7rem"
                              textAlign="right"
                           >
                              {node.modifiedGmt}
                           </WrapItem>
                        </Wrap>
                     </Box>
                  </HStack>
               </Link>
               </>
            )
         }))
         }

         <Text w="100%"
            rounded="sm"
            fontSize="1.3rem"
            fontWeight="bold"
            lineHeight="4rem"
            textAlign="center"
            color="#fff"
            bg="linkedin.800"
            position="relative"
            _before={{
               content:'""',
               display:"block",
               position:"absolute",
               top:"1.6rem",
               right:"2rem",
               w:"1rem",
               h:"1rem",
               borderTop:"0.2rem solid #fff",
               borderRight:"0.2rem solid #fff",
               transform:"rotate(45deg)"
            }}
            as={GatsbyLink}
            to={to}
         >もっと見る</Text>
      </Box> 
   )
}

export const TitleList4 = ({postList,to}) => {
   return (
      <Box px="0.75rem" display="flex" flexWrap="wrap">
         
         {React.Children.toArray(postList.map((node,index) => {
            const title = node.title;
            const uri = node.uri;
            const date = node.modifiedGmt;
            const excerpt = node.excerpt
               .replace("[toc]","")
               .replace("[holl_befor_sentence]","")
               .replace("[pachi_befor_sentence]","");
            const authorname = node.author.node.name;
            
            return (
               <>
               <Box bg="#fff" mb="1rem" w="100%" verticalAlign="top" boxSizing="border-box">
                  <Box pos="relative">
                     <AspectRatio w="100%" ratio={16 / 9}>
                        {
                           node.featuredImage?
                              <GatsbyImage 
                                 image={node.featuredImage.node.localFile.childImageSharp.gatsbyImageData} 
                                 alt={node.featuredImage.node.altText} 
                              />
                           :
                              <Image 
                                 w="100%" 
                                 verticalAlign="bottom" 
                                 fallbackSrc="https://via.placeholder.com/337x190" 
                              />
                        }
                     </AspectRatio>
                     {node.tags.nodes && 
                        <Box pos="absolute" top="0.5rem" left="0.5rem" >
                           {React.Children.toArray(node.tags.nodes.map((tag,index) => {
                              return (
                                 <Badge colorScheme="green" mr="0.2rem">
                                    {tag.name}
                                 </Badge>
                              )
                           }))}
                        </Box>
                     }
                     {authorname !== "admin" && 
                        <Box pos="absolute" bottom="0rem" right="0rem" >
                           <Box bg="#d1495b">
                              <Box p="0.2rem" fontSize="0.8rem" fontWeight="semibold" color="#fff">
                                 {authorname}
                              </Box>
                           </Box>
                        </Box>
                     }
                  </Box>
                  
                  <Box px="1rem">
                     <Box py="1rem">
                        <Link
                           as={GatsbyLink}
                           to={uri}
                           mt={1}
                           display="block"
                           fontSize="lg"
                           lineHeight="normal"
                           fontWeight="semibold"
                        >
                           {title}
                        </Link>
                     </Box>
                     <Text pb="1rem" fontSize="0.7rem" color="gray.500">
                        {Parser(excerpt)}
                     </Text>
                     <Text 
                        color="gray.500"
                        align="right" 
                        fontWeight="bold" 
                        fontSize="0.8rem" 
                        letterSpacing="wide" 
                        pb="1rem"
                     >
                        {date}
                     </Text>
                  </Box>
               </Box>
               </>
            )
         }))
      }
      </Box>
   )
}

export const SwiperTitleList1 = ({postList,to}) => {
   return (
      <>
         <Swiper 
            spaceBetween={20} 
            slidesPerView={2}
            centeredSlides={true} 
            autoplay={{
               "delay": 2500,
               "disableOnInteraction": false
            }} 
            // pagination={{
            //    "clickable": true
            // }} 
            navigation={true} 
            className="mySwiper"
         >
            {React.Children.toArray(postList.map((node,index) => {
               return(
                  <SwiperSlide>
                     <Box>
                        <Link as={GatsbyLink} to={node.uri}>
                           <AspectRatio w="100%" ratio={16 / 9}>
                              {
                                 node.featuredImage?
                                    <GatsbyImage 
                                       image={node.featuredImage.node.localFile.childImageSharp.gatsbyImageData} 
                                       alt={node.featuredImage.node.altText} 
                                    />
                                 :
                                    <Image 
                                       w="100%" 
                                       verticalAlign="bottom" 
                                       fallbackSrc="https://via.placeholder.com/337x190" 
                                    />
                              }
                           </AspectRatio>
                           <Text
                              pt="0.5rem"
                              pb="1rem"
                              px="1rem"
                              bg="#fff"
                              fontSize="1.0rem"
                              fontWeight="bold"
                              lineHeight="1.5rem"
                              boxSizing="border-box"
                              wordBreak="break-all"
                           >
                              {node.title}
                           </Text>
                        </Link>
                     </Box>
                  </SwiperSlide>
               )
            }))
            }
         </Swiper>
         <Center>
            <Button 
               w="60%"
               rounded="full"
               as={GatsbyLink} 
               to={to}
               m={5} 
               colorScheme="linkedin"
            >
               もっと見る
            </Button>
         </Center>
      </>
   )
}

export const SwiperTitleList2 = ({five_ranks}) => {
   const naiyoulist = ["総合","エンターテインメント","ゲーム","キッズ","美容・コスメ","格闘技・武芸・武道"];
   const colorlist = ["gold","silver","brown"];
   
   return (
      <>
         <Swiper 
            spaceBetween={20} 
            slidesPerView={2}
            centeredSlides={true} 
            autoplay={{
               "delay": 2500,
               "disableOnInteraction": false
            }} 
            // pagination={{
            //    "clickable": true
            // }} 
            // navigation={true} 
            className="mySwiper"
         >
            
            {React.Children.toArray(naiyoulist.map((naiyou) => {
               var genre = naiyou === "総合" ? "全て" : naiyou;
               return(
                  <SwiperSlide>
                     <Center 
                        fontWeight={"bold"}
                        fontSize="0.9rem"
                        lineHeight="1.4rem"
                     >
                        <Link
                           href={"/channellist"
                           + "?genre=" + encodeURIComponent(genre)
                        } 
                        >
                           {naiyou}
                        </Link>
                     </Center>
                     {five_ranks.finished ? 
                        // console.log(five_ranks.ranks[naiyou])
                        React.Children.toArray(five_ranks.ranks[naiyou].map((rank, index) => {
                           return(
                              <HStack my="0.4rem">
                                 {/* <Box fontSize={"1rem"}>{index+1}</Box> */}
                                 <Box boxSize="2.5rem" pos={"relative"}>
                                    <Image
                                       borderRadius="full"
                                       boxSize="2.5rem"
                                       src={rank.thumbnailUrl}
                                       alt={rank.channelTitle}
                                       
                                    />
                                 </Box>
                                    <Icon 
                                       color={colorlist[index]} 
                                       as={RiMedalFill} 
                                       h={6} 
                                       w={6} 
                                    />
                                 <Box 
                                    w="calc(100% - 3rem)" 
                                    textOverflow={"ellipsis"} 
                                    overflow={"hidden"}
                                    whiteSpace={"nowrap"}
                                 >
                                    <Link
                                       fontWeight={"bold"}
                                       fontSize="1.1rem"
                                       lineHeight="1.4rem"
                                       isExternal
                                       href={"/channeldetail"
                                          + "?ch=" + rank.channelId}
                                    >
                                          {rank.channelTitle}
                                    </Link>
                                 </Box>
                              </HStack>
                           )
                        }))
                     :
                        <></>
                     }
                  </SwiperSlide>
               )
            }))
            }
         </Swiper>
      </>
   )
}

export const SwiperTitleList3 = ({five_ranks}) => {
   // const naiyoulist = ["総合","エンターテインメント","ゲーム","キッズ","美容・コスメ","格闘技・武芸・武道"];
   const colorlist = ["gold","silver","brown"];
   
   return (
      <>
         <Swiper 
            spaceBetween={20} 
            slidesPerView={2}
            centeredSlides={true} 
            autoplay={{
               "delay": 2500,
               "disableOnInteraction": false
            }} 
            // pagination={{
            //    "clickable": true
            // }} 
            // navigation={true} 
            className="mySwiper"
         >
            
            {React.Children.toArray(five_ranks.map((fiverank) => {
               var genre = fiverank.key === "総合" ? "全て" : fiverank.key;
               return(
                  <SwiperSlide>
                     <Center 
                        fontWeight={"bold"}
                        fontSize="0.9rem"
                        lineHeight="1.4rem"
                     >
                        <Link
                           href={"/channellist"
                           + "?genre=" + encodeURIComponent(genre)
                        } 
                        >
                           {fiverank.key}
                        </Link>
                     </Center>
                     {
                        React.Children.toArray(fiverank.items.map((rank, index) => {
                           return(
                              <HStack my="0.4rem">
                                 {/* <Box fontSize={"1rem"}>{index+1}</Box> */}
                                 <Box boxSize="2.5rem" pos={"relative"}>
                                    <Image
                                       borderRadius="full"
                                       boxSize="2.5rem"
                                       src={rank.thumbnailUrl}
                                       alt={rank.channelTitle}
                                       
                                    />
                                 </Box>
                                    <Icon 
                                       color={colorlist[index]} 
                                       as={RiMedalFill} 
                                       h={6} 
                                       w={6} 
                                    />
                                 <Box 
                                    w="calc(100% - 3rem)" 
                                    textOverflow={"ellipsis"} 
                                    overflow={"hidden"}
                                    whiteSpace={"nowrap"}
                                 >
                                    <Link
                                       fontWeight={"bold"}
                                       fontSize="1.1rem"
                                       lineHeight="1.4rem"
                                       isExternal
                                       href={"/channeldetail"
                                          + "?ch=" + rank.channelId}
                                    >
                                          {rank.channelTitle}
                                    </Link>
                                 </Box>
                              </HStack>
                           )
                        }))
                     }
                  </SwiperSlide>
               )
            }))
            }
         </Swiper>
      </>
   )
}

export const Chart1 = ({data}) =>{
   // console.log(data.registCountTransition);
   // console.log(data.viewsCountTransition);
   // console.log(data.videoCountTransition);
   // let data0 = [];
   // if (data.registCountTransition != "") {
      
   //    //urlパラメータをオブジェクトにまとめる
   //    var regsplit = data.registCountTransition.split('zzz');
   //    for (let index = 0; index < regsplit.length; index++) {
   //       const element = regsplit[index];
   //       const temp = element.split('qq');
   //       data0.unshift({name: temp[0], registCount: parseInt(temp[1])});
   //       // if(data0.length > 30) break;
   //    }
   // }
   let registsplit = [];
   if (data.registCountTransition != "") {
      registsplit = data.registCountTransition.split('zzz').slice(0,30);
   }
   let viewssplit = [];
   if (data.viewsCountTransition != "") {
      viewssplit = data.viewsCountTransition.split('zzz').slice(0,30);
   }
   let videosplit = [];
   if (data.videoCountTransition != "") {
      videosplit = data.videoCountTransition.split('zzz').slice(0,30);
   }

   let data0 = [];
   let data1 = [];
   let data2 = [];
   let data3 = [];

   if(registsplit.length == viewssplit.length){
      for (let index = 0; index < registsplit.length; index++) {
         const temp1 = registsplit[index].split('qq');
         const temp2 = viewssplit[index].split('qq');
         const temp3 = videosplit[index].split('qq');
         const yyyymmdd = temp1[0].slice(0,10);
         data0.unshift({
            name: yyyymmdd, 
            registCount: parseInt(temp1[1]),
            viewsCount: parseInt(temp2[1]),
            videoCount: parseInt(temp3[1]),
         });
         data1.unshift({
            name: yyyymmdd, 
            登録者数: parseInt(temp1[1]),
         });
         data2.unshift({
            name: yyyymmdd, 
            再生回数: parseInt(temp2[1]),
         });
         data3.unshift({
            name: yyyymmdd, 
            ビデオ数: parseInt(temp3[1]),
         });
         // if(data0.length > 30) break;
      }
   }

   console.log(data0);

   const data4 = [
      { name: "a", uv: 12, pv: 5 },
      { name: "b", uv: 13, pv: 3 },
      { name: "c", uv: 19, pv: 9 },
      { name: "d", uv: 11, pv: 10 },
      { name: "e", uv: 9, pv: 12 },
      { name: "f", uv: 22, pv: 13 },
   ]
   return (
      <Box w='100%'>
      
         <Center>登録者数</Center>
         <ResponsiveContainer width="100%" height={200}>
            <LineChart
               data={data1}
               syncId="anyId"
               margin={{
               top: 10,
               right: 30,
               left: 0,
               bottom: 0,
               }}
            >
               <CartesianGrid strokeDasharray="3 3" />
               <XAxis dataKey="name" tick={{ fontSize: '0.7rem' }} padding={{ left: 20, right: 20 }}/>
               <YAxis domain={['dataMin', 'dataMax']}  tick={{ fontSize: '0.7rem' }} interval="preserveEnd" padding={{ top: 20, bottom: 20 }}/>
               <RechartsTooltip />
               <Line type="monotone" dataKey="登録者数" strokeWidth={3} stroke="#8884d8" fill="#8884d8" />
            </LineChart>
         </ResponsiveContainer>

         <Center>再生回数</Center>
         <ResponsiveContainer width="100%" height={200}>
            <LineChart
               width={500}
               height={200}
               data={data2}
               syncId="anyId"
               margin={{
               top: 10,
               right: 30,
               left: 0,
               bottom: 0,
               }}
            >
               <CartesianGrid strokeDasharray="3 3" />
               <XAxis dataKey="name" tick={{ fontSize: '0.7rem' }} padding={{ left: 20, right: 20 }}/>
               <YAxis domain={['dataMin', 'dataMax']}  tick={{ fontSize: '0.7rem' }} interval="preserveEnd" padding={{ top: 20, bottom: 20 }}/>
               <RechartsTooltip />
               <Line type="monotone" dataKey="再生回数" strokeWidth={3} stroke="#82ca9d" fill="#82ca9d" />
            </LineChart>
         </ResponsiveContainer>

         <Center>ビデオ数</Center>
         <ResponsiveContainer width="100%" height={200}>
            <BarChart 
               width={500}
               height={200}
               data={data3}
               syncId="anyId"
               margin={{
               top: 10,
               right: 30,
               left: 0,
               bottom: 0,
               }}
            >
               <CartesianGrid strokeDasharray="3 3" />
               <XAxis dataKey="name" tick={{ fontSize: '0.7rem' }} padding={{ left: 20, right: 20 }}/>
               <YAxis domain={['dataMin', 'dataMax']}  tick={{ fontSize: '0.7rem' }} interval="preserveEnd" padding={{ top: 20, bottom: 0 }}/>
               <RechartsTooltip />
               <Bar type="monotone" dataKey="ビデオ数" stroke="#82ca9d" fill="#82ca9d" />
            </BarChart>
         </ResponsiveContainer>
      </Box>
   )
}

export const AdSense = ({ format = "auto" }) => {
   //vertical,horizontal

   const [state, setState] = useState({ showAds: false })
   
   useEffect(() => {
     if (state.showAds) {
       window.adsbygoogle = window.adsbygoogle || []
       window.adsbygoogle.push({})
     }
     
     if (window) {
       if ( window.innerWidth > 1000 ) {
         setState({ showAds: true })
       }
     }
   }, [state.showAds])
   
   if (!state.showAds) return null
   
   return (
     <div>
       <ins 
         className="adsbygoogle"
         style={{ "display": "block" , textAlign: "center"  }}
         data-ad-client="ca-pub-2462017370728663"
         data-ad-slot="2663801178"
         data-ad-format={format}
         // data-adtest="on"
         data-full-width-responsive="true"
       />
     </div>
   );
 }

export const GoogleAd = ({ format = "auto" }) => {
   //  "rectangle"（レクタングル）、"vertical"（縦長）、"horizontal"（横長）
   useEffect(() => {
      if (!window) { return } // SSR 処理中は skip
      window.adsbygoogle = window.adsbygoogle || [];
      window.adsbygoogle.push({});
   },[]);

   return (
      <Center>
         <Box minW={"300px"}>
            {/* <!-- tubesearch0001 --> */}
            <ins 
               className="adsbygoogle"
               style={{ "display":"block"}}
               data-ad-client="ca-pub-2462017370728663"
               data-ad-slot="2663801178"
               data-ad-format={format}
               data-full-width-responsive="true"
            />
         </Box>
      </Center>
   );
}


////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
//https://www.polynique.com/web-development/how-to-add-a-table-of-contents-toc-to-gatsby-wordpress/
export const TableOfContent = ({ toc }) => (
   <>
   {toc &&
   <Box mb="1rem" border={"1px"} borderRadius={"sm"} borderColor={"#b0b0b0"} className={"toc"}>
      <Accordion allowMultiple>
      <AccordionItem>
         <Center >
            <AccordionButton fontSize={"1.2rem"} fontWeight={"600"}>
               <Box flex='1' textAlign='left'>
                  目次
               </Box>
               <AccordionIcon />
            </AccordionButton>
         </Center>
         <AccordionPanel pb={4}>
            {renderTableOfContentItems(toc.items)}
         </AccordionPanel>

      </AccordionItem>
      </Accordion>     
   </Box >
   }
   </>
)

export const renderTableOfContentItems = (items) => {
   return (
      <UnorderedList>
         {items.map(({ url, title, items }) => (
            <ListItem key={url}>
            <Scroll 
               to={url.replace("#","")}
               smooth={true}
               duration={300}
            >
               {title}
            </Scroll>
            {items && items.length && renderTableOfContentItems(items)}
            </ListItem>
         ))}
      </UnorderedList>
   )
}
///////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////