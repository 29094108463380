import React, { } from "react"
import { Box, 
   Link, 
   Text,
   Button,
   Center,
   Icon,
   VStack,
   Wrap,
   WrapItem,
   useToast
} from "@chakra-ui/react"
import { Link as GatsbyLink } from 'gatsby'
import { 
   AiOutlineTwitter,
   AiOutlineFacebook,
} from "react-icons/ai"
import { 
   HiOutlineClipboardCopy
} from "react-icons/hi"
import { FaLine } from "react-icons/fa"

export const Footer = (props) => {
   const toast = useToast();

   const kopipe = () => {
      navigator.clipboard.writeText(props.path);
      toast({
         title: "このページのURLをコピーしました",
         status: "info",
         duration: 1300,
      });
   }
   return (
      <>
      <Box mt="1rem" bg="#fff" mx="0.75rem" p="1rem" className="wordpress" fontSize="1rem">
         <Box >
         <Center mt="2rem" mb="-2rem">このページを共有する</Center>
         <Center>
            <Wrap m="0.5rem" justify="center">
               <WrapItem>
                  <Button 
                     colorScheme="blackAlpha" 
                     leftIcon={<Icon as={HiOutlineClipboardCopy} />}
                     onClick={() =>kopipe()}
                  >
                     URLをコピー
                  </Button>
               </WrapItem>
               <WrapItem>
                  <Link href={"https://facebook.com/share.php?u=" + props.path} textDecoration="none!important" isExternal>
                     <Button colorScheme="facebook" leftIcon={<Icon as={AiOutlineFacebook} />}>
                        Facebook
                     </Button>
                  </Link>
               </WrapItem>
               <WrapItem>
                  <Link href=
                     {
                        "http://twitter.com/share?url=" + props.path
                        + "&text=" + props.title
                        + "&via=" + props.twitterId
                        + "&related=" + props.twitterId
                     } 
                     textDecoration="none!important"
                     isExternal
                  >
                     <Button colorScheme="twitter" leftIcon={<Icon as={AiOutlineTwitter} />}>
                        Twitter
                     </Button>
                  </Link>
               </WrapItem>
               {/* http://twitter.com/share?url=https://dot.asahi.com/dot/2021111900025.html&text=愛子さまが借りるティアラ、清子さんからの理由は「格」の違い　秋篠宮家へは逆風やまず&via=dot_asahi_pub&related=dot_asahi_pub */}
               <WrapItem>
                  <Link href={"https://social-plugins.line.me/lineit/share?url=" + props.path} textDecoration="none!important" isExternal>
                     <Button colorScheme="green" leftIcon={<Icon as={FaLine} />}>
                        Line
                     </Button>
                  </Link>
               </WrapItem>
            </Wrap>
         </Center>
         </Box>
      </Box>
      </>
   )
}

export const Footerend = (props) => {
   return (
      <>
      <Box>
         <Center pt="3rem">
            <VStack>
               <Link as={GatsbyLink} to="/disclaimers">利用規約・免責事項・プライバシーポリシー</Link>
               <Link href="https://www.youtube.com/t/terms">YouTube利用規約</Link> | <Link href="https://policies.google.com/privacy?hl=ja">Googleプライバシーポリシー</Link>
               <Text>© Copyright 2020 <Link as={GatsbyLink}  to="/">バズクラッチ</Link> All Rights Reserved.</Text>
            </VStack>
         </Center>
      </Box>
      </>
   )
}
export default Footer;